import { Link } from 'gatsby';
import React from 'react';
import CTA from '../../components/CTA';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Skal vi tage en snak?" />

      <div className="bg-light-blue-50 w-full pt-36 pb-48">
        <div className="container">
          <h1 className="heading-sm text-center">Hej!</h1>
          <h2 className="sub-lg font-bold text-center mt-3">
            Skal vi tage en snak?
          </h2>
          <p className="sub-sm mx-auto max-w-3xl text-center mt-8">
            Vi står klar til at hjælpe dig i mål med en endnu bedre salgs- og
            indkøbsproces. Send os en besked, så tager vi fat på dig hurtigst
            muligt.
          </p>
        </div>
      </div>

      <div className="container max-w-3xl -mt-28 mb-28">
        <form
          className="bg-white shadow-xl rounded-lg p-4 md:p-6"
          method="POST"
          action="/contact/success"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <div className="grid grid-cols-1 gap-4">
            <p className="hidden">
              <input type="hidden" name="form-name" value="Contact Form" />
              <input
                type="hidden"
                name="subject"
                value="Kontakt form udfyldt på get-sails.dk"
              />
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            <label className="input-group">
              <span>Navn</span>
              <input
                type="text"
                name="name"
                placeholder="Dit fulde navn"
                className="input"
                required
              />
            </label>

            <label className="input-group">
              <span>Telefon</span>
              <input
                type="phone"
                name="phone"
                placeholder="Din telefon eller mobil"
                className="input"
                required
              />
            </label>

            <label className="input-group">
              <span>Email</span>
              <input
                type="email"
                name="name"
                placeholder="Din arbejdsmail"
                className="input"
                required
              />
            </label>

            <label className="input-group">
              <span>Virksomhed</span>
              <input
                type="text"
                name="company"
                placeholder="Din virksomheds navn"
                className="input"
              />
            </label>

            <label className="input-group">
              <span>Besked</span>
              <textarea
                name="message"
                placeholder="Skriv en besked her..."
                className="input"
                rows={4}
                required
              />
            </label>

            <label className="flex text-sm">
              <input
                type="checkbox"
                required
                className="flex-shrink-0 my-1 mr-2"
              />
              <span>
                Jeg giver samtykke til at Sails må behandle de oplysninger om
                mig, som er nødvendige for at Sails kan svare på min besked.
              </span>
            </label>

            <label className="pt-4">
              <button className="btn" role="submit">
                Send besked
              </button>
            </label>
          </div>
        </form>
      </div>

      <CTA />
    </Layout>
  );
};

export default ContactPage;
